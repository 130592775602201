<template>
  <div
    v-if="instructionsOpen"
    class="quick-instructions-wrapper flex flex-column items-center justify-center"
  >
    <div class="quick-instructions flex flex-column items-center justify-between relative">
      <div
        class="quick-instructions__hide flex items-center justify-center"
        @click="hideInstructions"
      >
        X
      </div>
      <div class="flex items-center">
        <div class="title mr2 uppercase">
          {{ $t('shortInstructions') }}
        </div>
        <LanguageSelection />
      </div>
      <div class="mt2 flex quick-instructions__content">
        <img class="mr2" :src="require(`@/assets/images/instructions.jpg`)" alt="Instructions" />
        <template v-for="(item, index) in instructions" :key="index">
          <div v-show="index === activeIndex" class="quick-instructions-item center pr3">
            {{ item }}
          </div>
        </template>
      </div>
      <div class="flex quick-instructions__menu">
        <Button
          :disabled="activeIndex === 0"
          class="p-button-sm mx1"
          @click="activeIndex = activeIndex - 1"
        >
          {{ $t('common.previous') }}
        </Button>
        <Button
          v-if="activeIndex < instructions.length - 1"
          class="p-button-sm mx1"
          @click="activeIndex = activeIndex + 1"
        >
          {{ $t('common.next') }}
        </Button>
        <Button
          v-if="activeIndex === instructions.length - 1"
          class="p-button-sm mx1"
          @click="hideInstructions"
        >
          {{ $t('common.finish') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import LanguageSelection from '@/components/common/LanguageSelection.vue';

  export default {
    name: 'QuickInstructions',
    components: {
      LanguageSelection
    },
    emits: ['hide'],
    props: {
      instructions: {
        type: Array,
        default: () => []
      }
    },
    data() {
      return {
        activeIndex: 0,
        instructionsOpen: true
      };
    },
    methods: {
      hideInstructions() {
        this.$emit('hide');
        this.instructionsOpen = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  .quick-instructions {
    width: 50vw;
    height: 50vh;
    max-width: 900px;
    max-height: 600px;
    background: white;
    color: color(primary);
    box-shadow: global-vars(ui-default-box-shadow--dark);
    border-radius: global-vars(ui-default-measure5x);
    padding: global-vars(ui-default-measure3x);

    @include mobile() {
      width: calc(100% - 32px);
      height: auto;
    }

    &-wrapper {
      position: fixed;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      z-index: 999;
    }

    &-item {
      @include font(16px, 500, 28px);
      width: 80%;
      margin: auto;

      @include mobile() {
        @include font(15px, 500, 20px);
        margin: 50px auto;
      }
    }

    &__hide {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: white;
      box-shadow: global-vars(ui-default-box-shadow--dark);
      @include font(20px, 700);
      position: absolute;
      top: -16px;
      right: -16px;
      cursor: pointer;
    }

    .p-button {
      margin: 0 8px;
    }

    &__content {
      img {
        width: 300px;
        height: auto;
        max-height: 30vh;

        @include md-down() {
          width: 200px;
        }

        @include mobile() {
          display: none;
        }
      }
    }
  }

  .title {
    @include font(22px, 600);
  }
</style>
