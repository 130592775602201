<template>
  <div class="icon-attribute" :class="$attrs.white && 'white'">
    Icons made by
    <a href="https://www.freepik.com" title="Freepik">Freepik,&nbsp;</a>
    <a href="https://www.flaticon.com/authors/smalllikeart" title="smalllikeart">
      smalllikeart,&nbsp;
    </a>
    <a href="https://www.flaticon.com/authors/smashicons" title="Smashicons">Smashicons&nbsp;</a>
    from
    <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
  </div>
</template>

<script>
  export default {
    name: 'IconAttribute'
  };
</script>

<style scoped lang="scss">
  .icon-attribute {
    position: fixed;
    bottom: 4px;
    left: 4px;
    font-size: 9px;
    max-width: 200px;

    @include xl-up {
      font-size: 10px;
    }

    &.white {
      color: white;
    }

    @include mobile() {
      display: none;
    }
  }
</style>
