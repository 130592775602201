
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'SoundMuting',
    setup() {
      const store = useStore();

      const toggleSound = () => store.commit('toggleSound', !soundOn.value);

      const soundOn = computed(() => store.state.soundOn);

      return {
        toggleSound,
        soundOn
      };
    }
  });
